<template>
  <v-app class="background">
    <template v-if="$store.state.auth.user.authenticated && !viewsWithVideo.includes($route.name)">
      <AppBarMain />
    </template>
    <div class="d-flex flex-column">
      <NavigationDrawer :key="$store.state.auth.account.country" v-if="$route.meta.displayNavigationDrawer"/>
      <v-row align="start" justify="center" no-gutters>
        <v-col cols="12" class="py-0" :style="$route.meta.displayNavigationDrawer && $route.name !== 'AuthChoose' ? 'padding-left: 25px !important; padding-right: 25px !important': ''">
          <v-main class="ma-0">
            <v-container fluid :class="{'pa-0' : $route.name === 'AuthChoose'}">
              <AlertDialog v-if="dialog && $route.name === 'AccountDashboard' && !hiddenAlertDialog" />
              <router-view />
            </v-container>
          </v-main>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
import moment from 'moment'
import locale from '@/locale'
import generalsPE from '@/locale/es_PE'
import generalsCL from '@/locale/es_CL'
import AppBarMain from '@/components/menu/AppBar'
import AlertDialog from '@/components/commons/AlertDialog'
import NavigationDrawer from '@/components/menu/NavigationDrawer'

export default {
  components: {
    NavigationDrawer,
    AppBarMain,
    AlertDialog
  },
  data: () => ({
    dialog: false,
    viewsWithVideo: ['Index', 'AuthSignIn', 'AuthSetup', 'AuthChoose', 'AuthSignUp', 'AuthPasswordReset', 'AuthPasswordResetConfirm']
  }),
  computed: {
    hiddenAlertDialog () {
      const endDate = '2024-09-26T08:00:00'
      return moment().isAfter(moment(endDate))
    }
  },
  watch: {
    $route (to, from) {
      if (to.path !== from.path) {
        this.$vuetify.goTo(0, { duration: '0', easing: 'linear' })
      }
      if (((from.name === 'AuthChoose' || !from.name) && to.name === 'AccountDashboard')) this.dialog = true
      else this.dialog = false
    },
    '$store.state.auth.account.country': {
      handler () {
        this.setMessagesI18n()
      },
      inmediate: true,
      deep: true
    }
  },
  created () {
    this.setMessagesI18n()
  },
  methods: {
    setMessagesI18n () {
      let currentLocale = {}
      let messages = (this.$store?.state?.auth?.account?.country ?? JSON.parse(localStorage.getItem('vuex'))?.auth?.account?.country) === 'PE' ? generalsPE : generalsCL
      currentLocale.es = {...messages, ...locale.es}
      this.$i18n._vm.messages = {...currentLocale}
    }
  }
}
</script>